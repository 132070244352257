import "core-js";
import '../styles/scss/theme.scss';
/* ------------------------------- */

//console.log('ThemeJS loaded');

// Document Ready, most of your code should be put in here
$(function() {
    //console.log('DOM is loaded');
	if (window.location.href.includes('/services/')) {
		try {
			new Vue({
				el: '#services',
				data: {
					search: '',
					filters: {
						category: 'all'
					},
					current: {
						start: 1,
						end: services.length
					},
					services
				},
				computed: {
					categories: function() {
						const list = {};
						this.services.forEach( function(service) {
							if (Array.isArray(service.categories)) {
								service.categories.forEach ( function(category) {
									list[category.term_id] = category;
								});
							}
						});

						return list;
					},				
					filteredData: function() {
						let filtered = this.services;
						let self = this;

						filtered.forEach( function(service) {
							if (Array.isArray(service.categories)) {
								service.show = service.categories.filter( function(category) {
									return category.term_id === self.filters.category;
								}).length > 0 || self.filters.category === 'all';
							}
						});

						return filtered;
					}
				},
				methods: {
					toTitleCase: function(str) {
						return str.replace(/\w\S*/g, function(txt) {
							return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
						});
					}
				}
			});	
		} catch (e) {
			console.log('detected service page but not a vue app.');
		}
	}
	if (window.location.href.includes('/find-a-doctor/')) {
		new Vue({
			el: '#providers',
			data: {
				search: byID() || '',
				filters: {
					service: 'all',
					advancePracticeProvider: true,
					doctor: true
				},
				current: {
					start: 1,
					end: providers.length
				},
				servicesData: services
			},
			computed: {
				serviceTitle: function() {
					return this.services.filter( service => this.filters.service == service.ID)[0].post_title
				},
				services: function() {
					let services = [];
					
					// find all services that actually have a doctor attached
					this.servicesData.forEach(function(service) {
						const serviceID = service.ID;
						providers.forEach(function(provider) {
							if (provider.fields.services.value) {
								provider.fields.services.value.forEach(function(providerService){
									if ( providerService.service.ID === serviceID ) {
										services.push(service);
									}
								})
							}
						})
						
					});
					
					 // remove duplicates
					services = services.filter(function (x, i, a) { 
						return a.indexOf(x) == i; 
					});

					// sort the by the service post name
					return services.sort(function(a, b) {
						return a.post_name < b.post_name ? -1 : 1;
					});
				},
				filteredData: function() {
					let filtered = providers;
					let self = this;

					if (this.search.length) {
						filtered = filtered.filter(function(el) {
							let post = (
								el.post_title +
								' ' +
								el.post_content +
								' ' +
								el.post_name + 
								'' + 
								el.fields.first_name.value +
								'' + 
								el.fields.last_name.value
							).toLowerCase();
							let search = self.search.toLowerCase();
							return post.includes(search);
						});
					}

					if ( !this.filters.advancePracticeProvider || !this.filters.doctor ){
						if ( this.filters.advancePracticeProvider ) {
							filtered = filtered.filter(function(doctor) {
								return doctor.fields.advanced_practice_provider && doctor.fields.advanced_practice_provider.value;
							});
						}
						if ( this.filters.doctor ) {
							filtered = filtered.filter(function(doctor) {
								// either hasn't been set as an APP or it has been set to false
								return !doctor.fields.advanced_practice_provider || doctor.fields.advanced_practice_provider && !doctor.fields.advanced_practice_provider.value;
							});
						}
					}
					

					if ( this.filters.service && this.filters.service != 'all' ) {
						filtered = filtered.filter(function(doctor) {
							return doctor.fields.services && doctor.fields.services.value && doctor.fields.services.value.filter(function(service) {
								return service.service.ID === self.filters.service;
							})[0];
						});
					}

					// filtered.sort(function(a, b) {
					// 	return a.fields.last_name.value < b.fields.last_name.value ? -1 : 1;
					// });

					filtered.sort(function(a, b) {
						if (a.fields.hillsdale_doctor && a.fields.hillsdale_doctor.value && (!b.fields.hillsdale_doctor || !b.fields.hillsdale_doctor.value)) return -1;
						if (b.fields.hillsdale_doctor && b.fields.hillsdale_doctor.value && (!a.fields.hillsdale_doctor || !a.fields.hillsdale_doctor.value)) return 1;

						// console.log(`${a.fields.last_name.value}: ${b.fields.last_name.value} : ${a.fields.last_name.value < b.fields.last_name.value}`)
						return a.fields.last_name.value < b.fields.last_name.value ? -1 : 1;
					});

					return filtered;
				}
			},
			methods: {
				toTitleCase: function(str) {
					return str.replace(/\w\S*/g, function(txt) {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				},
				servicesString: function(post) {
					if (post.fields.services.value) {
						return post.fields.services.value.reduce(function(acc, curr){ 
							return acc ? acc + ", " + curr.service.post_title : curr.service.post_title;
						}, "");
					}

				},				
			}
		});	
	}
});

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function byID() {
	var id = getUrlParameter('id');
	var provider = providers.filter( function(doctor) {
		return id == doctor.ID;
	} )[0];
	return provider && provider.fields.first_name && provider.fields.last_name ? provider.fields.first_name.value + ' ' + provider.fields.last_name.value : '';
}
